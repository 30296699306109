<template>
  <v-container fluid>
    <v-card color="grey lighten-5" elevation="0">
      <v-card-title>
        <h3 class="font-weight-black display-0 basil--text">
          KARDEX
        </h3>
      </v-card-title>
    </v-card>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        xs="12"
        md="6"
      >
        <v-autocomplete
          v-model="filterProductKardex"
          :items="kardexFilter"
          item-text="name"

          item-value="id"
          clearable
          outlined
          dense
          label="Filtrar por Producto"
          class="mt-7"
          @input="fetchKardex(filterProductKardex)"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="kardex"
      class="elevation-1"
      :footer-props="{ 'items-per-page-options': [5, 10, 20, 50] }"
      :server-items-length="totalKardex"
      :options.sync="options"
    ></v-data-table>
  </v-container>
</template>

<script>
import axios from "axios";
import {mapState} from "vuex";
export default {
  name: "Stock",
  data () {
    return {
      headers: [
        { text: 'PRODUCTO',       value: 'product.name',               sortable: false, class: "success white--text"},
        { text: 'FECHA',          value: 'date',                       sortable: false, class: "success white--text"},
        { text: 'MOVIMIENTO',     value: 'movement.name',              sortable: false, class: "success white--text"},
        { text: 'CANTIDAD',       value: 'quantity',                   sortable: false, class: "success white--text"},
        { text: 'RESPONSABLE',    value: 'assigment.person.full_name', sortable: false, class: "success white--text"},
        { text: 'STOCK ANTERIOR', value: 'previousStock',              sortable: false, class: "success white--text"},
        { text: 'STOCK ACTUAL',   value: 'currentStock',               sortable: false, class: "success white--text"},
        { text: 'SEDE',           value: 'entity.name',                sortable: false, class: "success white--text"},
        { text: 'COMPROBANTE / DETALLE',    value: 'voucher',                    sortable: false, class: "success white--text"},
      ],
      kardex: [],
      kardexFilter: [],
      filterProductKardex: '',
      totalKardex: 0,
      options: {},
    }
  },
  created() {
    this.getKardex()
    this.filterKardex()
  },
  computed: {
    ...mapState('auth', ['user']),
    search: {
      get () {
        return this.filters.search
      },
      set (value) {
        this.filters.search = value
        this.getKardex()
      }
    },
  },
  watch: {
    options: {
      async handler () {
        await this.getKardex();
      }
    },
  },
  methods: {
    getKardex () {
      const params = { ...this.options }
      let modifyList = []
      let quotient = 0
      let rest     = 0
      axios.get('/api/products/kardex', {params})
        .then(response => {
          this.kardex = response.data.data.data
          this.totalKardex = response.data.data.total
          modifyList.filter((value) => {

              if (value.product.box_quantity > 1) {
                quotient = parseInt(value.quantity) % parseInt(value.product.box_quantity)
                rest  = (parseInt(value.quantity) - parseInt(quotient)) / parseInt(value.product.box_quantity)
                value.quantity = rest + 'F' + quotient
              }else {
                value.quantity = parseInt(value.quantity)
              }
              return value.product.quantity


          })



        })
        .catch(error => console.log(error))
    },
    filterKardex () {
      axios.get('/api/products/kardex/filter')
        .then(response => {
          this.kardexFilter = response.data.data
        })
        .catch(error => console.log(error))
    },
    fetchKardex (filter) {
      if (filter===null){
        this.getKardex()
      }
      else{
        let request = {
          'product_id': filter,
          'entity_id': this.user[0].area.entity.id
        }
        axios.post('/api/products/kardex/fetch', request)
            .then(response => {
              this.kardex = response.data.data.data
              this.totalKardex = response.data.total
              })


            .catch(error => console.log(error))
      }

    },
  }

}
</script>
